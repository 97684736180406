const science = document.querySelector('.Wissenschaft')
const health = document.querySelector('.Gesundheit')
const medicine = document.querySelector('.Medizin')
// const random = document.querySelector('.Allgemein')
const scienceBtn = document.querySelector('.science-btn')
const healthBtn = document.querySelector('.health-btn')
const medicineBtn = document.querySelector('.medicine-btn')
const randomBtn = document.querySelector('.random-btn')
const allBtn = document.querySelector('.all-btn')

scienceBtn.addEventListener('click', (event) => {
  event.preventDefault()
  science.classList.remove('hidden')
  health.classList.add('hidden')
  medicine.classList.add('hidden')
  //   random.classList.add('hidden')
})

healthBtn.addEventListener('click', (event) => {
  event.preventDefault()
  science.classList.add('hidden')
  health.classList.remove('hidden')
  medicine.classList.add('hidden')
  //   random.classList.add('hidden')
})

medicineBtn.addEventListener('click', (event) => {
  event.preventDefault()
  science.classList.add('hidden')
  health.classList.add('hidden')
  medicine.classList.remove('hidden')
  //   random.classList.add('hidden')
})

randomBtn.addEventListener('click', (event) => {
  event.preventDefault()
  science.classList.add('hidden')
  health.classList.add('hidden')
  medicine.classList.add('hidden')
  //   random.classList.remove('hidden')
})

allBtn.addEventListener('click', (event) => {
  event.preventDefault()
  science.classList.remove('hidden')
  health.classList.remove('hidden')
  medicine.classList.remove('hidden')
  //   random.classList.add('show')
})
