const slides = document.querySelectorAll('.testimonials__logo')
const dots = document.querySelectorAll('.testimonials__progessbar-dots')
const button = document.querySelectorAll('.button')

let current = 0
let prev = 2
let next = 1

for (let i = 0; i < button.length; i++) {
  button[i].addEventListener('click', () => (i == 0 ? gotoPrev() : gotoNext()))
}

const gotoPrev = () =>
  current > 0 ? gotoNum(current - 1) : gotoNum(slides.length - 1)

const gotoNext = () => (current < 2 ? gotoNum(current + 1) : gotoNum(0))

const gotoNum = (number) => {
  current = number
  prev = current - 1
  next = current + 1

  for (let i = 0; i < slides.length; i++) {
    slides[i].classList.remove('active-logo')
    slides[i].classList.remove('prev-logo')
    slides[i].classList.remove('next-logo')
    dots[i].classList.remove('active-dot')
    dots[i].classList.remove('prev-dot')
    dots[i].classList.remove('next-dot')
  }

  if (next == 3) {
    next = 0
  }

  if (prev == -1) {
    prev = 2
  }

  slides[current].classList.add('active-logo')
  slides[prev].classList.add('prev-logo')
  slides[next].classList.add('next-logo')
  dots[current].classList.add('active-dot')
  dots[prev].classList.add('prev-dot')
  dots[next].classList.add('next-dot')
}
