const closeButton = document.querySelector('.nav__close')
const openButton = document.querySelector('.nav__open')
const nav = document.querySelector('.nav')

closeButton.addEventListener('click', () => {
  nav.classList.remove('navigation__open')
})

openButton.addEventListener('click', () => {
  nav.classList.add('navigation__open')
})
